import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple, red } from '@material-ui/core/colors';
import _ from 'lodash';
import { connect } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: "#636366",
  },
}));

function UserAvatar({...otherProps}) {
  
  const classes = useStyles();

  if (!otherProps.isLoggedIn && _.isEmpty(otherProps.lastUser)) {
    return (
      <div className={classes.root}>
        <Avatar className={classes.purple} />
      </div>
    );
  }
  
  const getUserChars = () => {
    const userName = otherProps.user.name || otherProps.lastUser.name;
  
    let nameSplit = userName.split(' ');
  
    let chars = '';
    
    if (nameSplit.length === 1) {
      chars += nameSplit[0][0]; // first char
      chars += nameSplit[nameSplit.length -1][1]; // last char
      return chars;
    }
  
    chars += nameSplit[0][0]; // first char of first element
    chars += nameSplit[nameSplit.length -1][0]; // first char of last element
    
    return chars;
  };
  
  return (
    <div className={classes.root} title={otherProps.user.name || otherProps.lastUser.name}>
      <Avatar className={classes.purple}>{getUserChars()}</Avatar>
    </div>
  );
}

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data,
  lastUser: state.user.lastUser
});

export default connect(mapStateToProps)(UserAvatar);

import { useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Button,
  Paper,
  Stepper, Step, StepLabel, StepContent,
  Grid, Hidden, Box
} from '@material-ui/core';
import PageHeader from '../../../components/pageHeader/pageHeader';
import PageLoader from '../../../components/util/PageLoader';
import { connect } from 'react-redux';
import { urlRedirectSet } from '../../../store/url/urlActions';
import useStyles from './orderConfirmationPage.style';
import DateTime from '../../../components/order/dateTime';
import CouponSelection from '../../../components/order/couponSelection'
import _ from 'lodash';
import CartPreview from '../../../components/cart/cartPreview';
import AddressSelection from '../../../components/order/addressSelection';
import CardPayment from '../../../components/order/cardPayment';
import Helmet from 'react-helmet';
import { API_DELIVERY_CHARGE, API_DISCOUNT_APPLY, API_STORE_ORDER, API_USER_POINTS, CURRENCY_SYMBOL, PAYMENT_API_FORM_DEV_URL, PAYMENT_API_FORM_PROD_URL, PAYMENT_API_FORM_URL, STRIPE_PAYMENT_KEY_DEV, STRIPE_PAYMENT_KEY_PROD } from '../../../util/constants';
import useNotify from "../../../hooks/useNotify";
import { useHistory } from "react-router-dom";
import routes from "../../../util/routes";
import { apiRequest } from '../../../util/util';
import { cartCouponUpdate, cartDeliveryChargeUpdate, cartDiscountUpdate, cartPaymentMethodUpdate, updateAdjustPointMoney, updateCartOrderPoints, updateCartPointsToMoney } from '../../../store/cart/cartActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ArrowBackIos } from "@material-ui/icons";
import useSetting from '../../../hooks/useSetting';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddPoint from '../../../components/order/addPoint';
import { updateUserPoints } from '../../../store/user/userActions';
import SlideAnimation from '../../../components/util/Animations/SlideAnimation';


function OrderConfirmationPage({ ...otherProps }) {

  const [notify] = useNotify();

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [btnOrderTxt, setBtnOrderTxt] = useState("Place My Order");

  const [btnEnable, setBtnEnable] = useState(false);

  const appSetting = useSetting([
    'app_debug',
    'points_enabled'
  ]);

  const [pointApiObject, setPointApiObject] = useState({});

  //checking if user login or not
  useEffect(() => {

    if (!otherProps.user.isLoggedIn) {

      otherProps.setUrlRedirect(otherProps.location.pathname, routes.login);

      // redirect
      otherProps.history.push(routes.login);

      return;
    }
  });

  useEffect(()=>{
    otherProps.updateCartOrderPoints(0);

    otherProps.updateCartPointsToMoney(0);

    otherProps.updateAdjustPointMoney(0);
    
    otherProps.cartPaymentMethodUpdate('');
  },[]);

  //is user login then calculate discount over ordertype
  useEffect(async () => {

    otherProps.cartDeliveryChargeUpdate('', parseFloat(0));

    if (!otherProps.user.isLoggedIn) return;



    const totalAmount = (otherProps.totalItemCost + otherProps.totalAddonsCost).toFixed(2);


    const discountRequestData = {
      total: totalAmount,
      order_type: otherProps.orderType.toLowerCase(),
    };

    const discountResponse = await apiRequest.post(API_DISCOUNT_APPLY, discountRequestData);

    if (discountResponse.data.status) {

      const discountSaveData = {
        amount: discountResponse.data.data.amount.toFixed(2),
        type: discountResponse.data.data.type,
        value: discountResponse.data.data.value,
      };

      otherProps.cartDiscountUpdate(discountSaveData);

      notify.success("Discount Applied!!");


    } else {

      if (discountResponse.data.messages != "Not available") {

        notify.info(discountResponse.data.messages);

      }
    }


  }, [otherProps.user.isLoggedIn, otherProps.orderType]);

  const [totalSteps, setTotalSteps] = useState([]);
  let deliverySteps;
  let collectionSteps;
  let step;


  if (appSetting.points_enabled) {
    step = 4;
    deliverySteps = ['Select Time & Date', 'Select Address', 'Add Coupon (if you have)', `Use your points`, 'Complete Payment'];

    collectionSteps = ['Select Time & Date', 'Add Coupon (if you have)', `Use your points`, 'Complete Payment'];
  } else {
    step = 3;
    deliverySteps = ['Select Time & Date', 'Select Address', 'Add Coupon (if you have)', 'Complete Payment'];

    collectionSteps = ['Select Time & Date', 'Add Coupon (if you have)', 'Complete Payment'];
  }

  useMemo(() => {

    if (otherProps.orderType === "Delivery") {
      setTotalSteps(deliverySteps);
    } else {
      setTotalSteps(collectionSteps);
    }

  }, [otherProps.orderType]);


  function getSteps() {
    return totalSteps;
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <DateTime />;
      case 1:
        return <AddressSelection />;
      case 2:
        return <CouponSelection />;
      case 3:
        if (appSetting.points_enabled) {
          return <AddPoint orderPoints={pointApiObject} />;

        } else {
          return <CardPayment />;
        }
      case 4:
        return <CardPayment />;
      default:
        return 'Unknown step';
    }
  }

  function getCollectionContent(step) {
    switch (step) {
      case 0:
        return <DateTime />;
      case 1:
        return <CouponSelection />;
      case 2:
        if (appSetting.points_enabled) {
          return <AddPoint orderPoints={pointApiObject} />;

        } else {
          return <CardPayment />;
        }
      case 3:
        return <CardPayment />;
      default:
        return 'Unknown step';
    }
  }

  // redirect user to menu page if there's no item in cart
  useEffect(() => {
    if (!otherProps.cart.items.length) {

      notify.warning('Please add some item to continue.');

      history.push(routes.order);
    }

  }, []);



  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);

  const steps = getSteps();


  const handleNext = async () => {

    // handle date
    if (!otherProps.cart.delivery.date.length) {
      notify.error('Please select a date');
      return;
    }

    // handle time, Allow if time is ASAP
    if (!otherProps.cart.delivery.isAsapTime && !otherProps.cart.delivery.time.length) {
      notify.error('Please specify time');
      return;
    }

    if (!otherProps.cart.delivery.isAsapTime && otherProps.UnavailableOrderTypeWithTime !== "") {
      notify.error(`please select a valid time`);
      return;
    }

    if (otherProps.orderType === "Delivery") {
      // handle address
      if (_.isEmpty(otherProps.cart.delivery.address) && activeStep === 1) {
        notify.error('Please select an address');
        return;
      }
    }




    if (activeStep === steps.length - 3 && appSetting.points_enabled) {
      setLoading(true);
      try {

        let totalAmount = otherProps.totalItemCost + otherProps.totalAddonsCost;
        totalAmount -= otherProps.orderDiscount.value;
        totalAmount -= otherProps.orderCoupon.value;

        const pointsObject = {
          total_amount: totalAmount,
        }

        const pointApi = await apiRequest.post(API_USER_POINTS, pointsObject);

        if (pointApi.data.status) {
          setLoading(false)
          otherProps.updateUserPoints(parseInt(pointApi.data.data.total_points));
          setPointApiObject(pointApi.data.data);
        } else {
          setLoading(false);
          notify.error("Something went wrong, Please try later");
          return;
        }

      } catch (e) {
        setLoading(false);
        notify.error("Something went wrong, Please try later");
        return;
      }

    }

    if (activeStep === steps.length - 1) {

      setBtnEnable(true);

      setLoading(true);

      setBtnOrderTxt("Processing...");
      // save order
      const getOrderResponse = await saveOrder();

      if (getOrderResponse?.status) {

        setLoading(false);

        setBtnOrderTxt("Place My Order");

        setBtnEnable(false);

        notify.success("Your order has been placed");
        // send to success page
        history.push(routes.orderSuccess);

      } else {

        setLoading(false);

        setBtnEnable(false);

        setBtnOrderTxt("Place My Order");

        notify.error('Something went wrong, Please try again in a while.');
      }
    }

    if (activeStep === steps.length - step && otherProps.orderType === "Delivery") {

      setLoading(true);
      setBtnEnable(true);
      const totalItemCost = otherProps.totalItemCost + otherProps.totalAddonsCost;

      const deliveryObject = {
        total: totalItemCost.toFixed(2),
        postcode: otherProps.deliveryAddress.postcode,
      }

      const deliveryChargeResponse = await apiRequest.post(API_DELIVERY_CHARGE, deliveryObject);

      if (deliveryChargeResponse.data.status) {

        setLoading(false);
        setBtnEnable(false);

        otherProps.cartDeliveryChargeUpdate(deliveryChargeResponse.data.data.distance, deliveryChargeResponse.data.data.charge);
        if (deliveryChargeResponse.data.data.charge === 0) {
          notify.success(`You are getting free delivery`);

        } else {
          notify.info(`Delivery Charge Added ${CURRENCY_SYMBOL} ${deliveryChargeResponse.data.data.charge}`);

        }
      } else {

        setLoading(false);
        setBtnEnable(false);

        notify.error(deliveryChargeResponse.data.messages);
        return; //don't let user to next step if there has any error in address selection.
      }


    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };


  const saveOrder = async () => {

    let totalAmount = otherProps.totalItemCost + otherProps.totalAddonsCost;
    totalAmount += otherProps.orderDelivery.charge;
    totalAmount -= otherProps.orderDiscount.value;
    totalAmount -= otherProps.orderCoupon.value;
    totalAmount -= otherProps.cart.pointsToMoney;
    totalAmount += parseFloat(otherProps.cart.adjustPointMoney);

    const { cart } = otherProps;

    const orderRequestData = {
      items: cart.items.map(itm => ({
        id: itm.id,
        qty: itm.qty,
        addons: itm?.selected_addon.map(addon =>({
          id: addon.id,
        })),
      })),
      // addons: cart.addons.map(itm => ({
      //   id: itm.id,
      //   item_id: itm.item_id
      // })),
      coupon: {
        ...cart.coupon,
        amount: cart.coupon.value,
      },
      discount: {
        ...cart.discount,
        amount: cart.discount.value.toFixed(2),
      },
      points: cart.orderPoint,
      delivery: {
        address_id: cart.delivery.address.id,
        charge: cart.delivery.charge,
        distance: cart.delivery.distance,
        date: cart.delivery.date,
        time: cart.delivery.time,
        is_asap: cart.delivery.isAsapTime
      },
      order: cart.order,
      payment: {
        ...cart.payment,
        amount: totalAmount.toFixed(2),
        trxId: '',
      }
    };

    if (otherProps.orderType === "Collection") {
      orderRequestData.delivery = {
        address_id: "",
        date: cart.delivery.date,
        time: cart.delivery.time,
        is_asap: cart.delivery.isAsapTime,

      };
    }

    try {

      const orderResponse = await apiRequest.post(API_STORE_ORDER, orderRequestData);
      return orderResponse.data;

    } catch (e) {
      notify.error('Something went wrong, Please try again in a while.');
      setLoading(false);

      setBtnOrderTxt("Place My Order");

      setBtnEnable(false);
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const backToMenuPage = () => {
    history.push(routes.order);
  };

  const getPaymentFormUrl = () => {
    return appSetting.app_debug ? PAYMENT_API_FORM_DEV_URL : PAYMENT_API_FORM_PROD_URL;
  }

  const getStripePromise = () => {
    return appSetting.app_debug ? loadStripe(STRIPE_PAYMENT_KEY_DEV) : loadStripe(STRIPE_PAYMENT_KEY_PROD);
  }

  return (<>

    <Helmet>
      <script src={getPaymentFormUrl()} />
    </Helmet>


    <Elements stripe={getStripePromise()}>
      <SlideAnimation in={true}>
        <PageLoader show={false} />
        <PageHeader
          pageName="Order Confirmation"
          breadCrumbComponentPadding="10px 0"
          breadCrumbComponent={
            <Button
              variant="contained"
              color="primary"
              onClick={backToMenuPage}
            >
              <ArrowBackIos />
              Back To Menu
            </Button>
          }
        />

        <Grid container justify="center">
          <Grid item xs={12} md={8}>
            <div className={classes.root}>
              <Stepper activeStep={activeStep} orientation="vertical" className="order-confirmation-stepper">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>

                      {/*content*/}
                      {
                        otherProps.orderType === "Delivery" &&
                        <Typography component={Box}>{getStepContent(index)}</Typography>

                      }

                      {
                        otherProps.orderType === "Collection" &&
                        <Typography component={Box}>{getCollectionContent(index)}</Typography>

                      }

                      {/*buttons*/}
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={btnEnable}
                            onClick={handleNext}
                            className={classes.button}
                          >
                            {
                              loading &&
                              <CircularProgress color="primary" size={25} />

                            }
                            {activeStep === steps.length - 1 ? btnOrderTxt : 'Next'}

                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                  <Typography>Something Went Wrong - Please&apos;Try Again.</Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    Try again
                  </Button>
                </Paper>
              )}
            </div>
          </Grid>

          <Hidden mdDown>
            <Grid item xs={12} md={4}>
              <CartPreview />
            </Grid>
          </Hidden>
        </Grid>
      </SlideAnimation>
    </Elements>

  </>);

}

const mapStateToProps = state => ({
  user: state.user,
  cart: state.cart,
  totalItemCost: state.cart.itemsTotal,
  totalAddonsCost: state.cart.addonsTotal,
  orderDiscount: state.cart.discount,
  orderCoupon: state.cart.coupon,
  orderDelivery: state.cart.delivery,
  deliveryAddress: state.cart.delivery.address,
  orderType: state.cart.order.type,
  UnavailableOrderTypeWithTime: state.opening.unavailableOrderTypeWithSelectedTime,
});

const mapDispatchToProps = dispatch => ({
  setUrlRedirect: (from, to) => dispatch(urlRedirectSet(from, to)),
  cartDeliveryChargeUpdate: (distance, charge) => dispatch(cartDeliveryChargeUpdate(distance, charge)),
  cartCouponUpdate: (coupon) => dispatch(cartCouponUpdate(coupon)),
  cartDiscountUpdate: (discount) => dispatch(cartDiscountUpdate(discount)),
  updateCartOrderPoints: (points) => dispatch(updateCartOrderPoints(points)),
  updateCartPointsToMoney: (amount) => dispatch(updateCartPointsToMoney(amount)),
  updateUserPoints: (points) => dispatch(updateUserPoints(points)),
  updateAdjustPointMoney: (amount) => dispatch(updateAdjustPointMoney(amount)),
  cartPaymentMethodUpdate: (method) => dispatch(cartPaymentMethodUpdate(method)),

});

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationPage);

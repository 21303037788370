import React from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';
import ThemeImage from '../../components/util/ThemeImage';
import { connect } from 'react-redux';
import _ from 'lodash';
import Styles from '../../assets/css/custom.scss';

function Awards() {



	return (<>

		<PageHeader pageName="Awards" bgClass="breadcrumb-bg-services" />

		{/* features-4 */}

		<section className="grids-1">
			<div className={`grids pt-3 pb-5 ${Styles.backgroundDark}`}>
				<div className="container">
					<h2 className="text-center exo2">Our Achievements</h2>
					<div className="row text-center grids-gap pt-4">
						<div className="col-lg-6 col-md-4 col-sm-6">
							<div className="">
							<ThemeImage  src="images/award1.jpg" className="img-fluid" />
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 mt-sm-0 mt-4">
							<div className="">
							<ThemeImage src="images/award2.jpg" className="img-fluid" />
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 mt-md-0 mt-4">
							<div className="">
							<ThemeImage style={{marginTop:25}} src="images/award3.jpg" className="img-fluid" />
							</div>
						</div>
						<div className="col-lg-6 col-md-4 col-sm-6 mt-lg-0 mt-4">
							<div className="">
							<ThemeImage style={{marginTop:25, width:600, height: 600}} src="images/award4.jpg" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>


	</>);
}

const mapStateToProps = state => ({
	user: state.user,
	date: state.cart.delivery.date,
	time: state.cart.delivery.time,
	opening: state.opening.data,
});

export default connect(mapStateToProps)(Awards);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PopupMobile from '../util/popupMobile';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import AddToCartAddons from './addToCartAddons';
import { connect } from 'react-redux';
import { cartItemAdd, cartItemAddonsUpdate } from '../../store/cart/cartActions';
import useNotify from '../../hooks/useNotify';


function CartItemAddonsPopup({ item, updating = false, openCallback, closeCallback, ...otherProps }) {

  const [open, setOpen] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState([])
  const [notify] = useNotify();

  // execute open & close callback
  useEffect(() => {
    if (open && typeof openCallback === 'function') {
      openCallback();
    }

    if (!open && typeof closeCallback === 'function') {
      closeCallback();
    }
  }, [open]);

  const addToCartItemHandler = () => {

    otherProps.cartAddItem(item, selectedAddons, updating);
    notify.success("Item Added Successfully");

    //close popup
    setOpen(false);
    setSelectedAddons([]);
  };

  const showAddonsHandler = () => {

    // don't show addons popup if there's no addon
    if (!item.addons.length) {

      // add the item to cart
      addToCartItemHandler();
      return;
    }

    // open addons
    setOpen(true);
  };

  return (<>
    {/*popup show element*/}
    {
      otherProps.children(showAddonsHandler)
    }

    <PopupMobile open={open} openHandler={setOpen} title="Select Addons" >
      <Box>
        <AddToCartAddons
          item={item}
          updating={updating}
          selectHandler={setSelectedAddons}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          color="primary"
          variant="contained"
          onClick={addToCartItemHandler}
          disabled={otherProps.isOffDay}
        >
          {
            otherProps.isOffDay
              ? `Today we're closed`
              : updating ? 'Update Item' : 'Add To Cart'
          }
        </Button>
      </Box>
    </PopupMobile>
  </>);
}

CartItemAddonsPopup.propTypes = {
  item: PropTypes.object.isRequired,
  updating: PropTypes.bool,
  children: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isOffDay: state.opening.isOffDay
});

const mapDispatchToProps = dispatch => ({
  cartAddItem: (item, selectedAddon, isUpdate) => dispatch(cartItemAdd(item, selectedAddon,isUpdate)),
  cartUpdateItemAddon: (item, addons) => dispatch(cartItemAddonsUpdate(item, addons)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItemAddonsPopup);

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { Edit, ExposureNeg1, ExposurePlus1, MoreVert } from '@material-ui/icons';
import { connect } from 'react-redux';
import {cartItemAdd, cartItemAddonsUpdate, cartItemRemove} from '../../store/cart/cartActions';

import CartItemAddonsPopup from './cartItemAddonsPopup';
import useNotify from '../../hooks/useNotify';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.grey['300'],
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

function CartItemMenu({item, qty, ...otherProps}) {
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const [notify] = useNotify();
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const addItemHandler = () => {
    console.log("add item", item);
    otherProps.cartAddItem(item, item?.selected_addon);
    notify.success("Item Quantity Increased");
  }
  
  const removeItemHandler = () => {

    const itemQtyInCart = otherProps.cartItems.find(itm => itm.id === item.id).qty;

    // remove item addons if item gets completely removed from cart
    if (itemQtyInCart < 2) {
      otherProps.cartUpdateItemAddon(item, item?.selected_addon);
    }

    otherProps.cartRemoveItem(item, item?.selected_addon);
    notify.warning("Item Quantity Decreased");
  }
  
  return (
    <Box>
      <IconButton
        aria-label="menu"
        size="small"
        onClick={handleClick}
      >
        <Box display="flex" justifyContent="center" alignItems="center" border={1} borderColor="primary.main" borderRadius="5px">
          <Box ml="8px" fontWeight="900" fontSize="20px">{qty}</Box>
          <Box>
            <MoreVert color="secondary" />
          </Box>
        </Box>
      </IconButton>
      
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={addItemHandler}>
          <ListItemIcon>
            <ExposurePlus1 color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Add" />
        </StyledMenuItem>
        <StyledMenuItem onClick={removeItemHandler}>
          <ListItemIcon>
            <ExposureNeg1 color="secondary" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Remove" />
        </StyledMenuItem>
  
        {/* display addons menu only when item has addons*/}
        {
          !!item.addons.length &&
          <CartItemAddonsPopup
            item={item}
            updating={true}
            openCallback={handleClose}
          >
            {
              (showToggler) =>(
                <StyledMenuItem onClick={showToggler}>
                  <ListItemIcon>
                    <Edit color="secondary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Addon" />
                </StyledMenuItem>
              )
            }
          </CartItemAddonsPopup>
        }
        
      </StyledMenu>
    </Box>
  );
}

const mapStateToProps = state => ({
  cartItems: state.cart.items
});

const mapDispatchToProps = dispatch => ({
  cartAddItem: (item, addon) => dispatch(cartItemAdd(item, addon)),
  cartRemoveItem: (item, addon) => dispatch(cartItemRemove(item, addon)),
  cartUpdateItemAddon: (item, addons) => dispatch(cartItemAddonsUpdate(item, addons)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItemMenu);

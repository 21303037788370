import {useEffect, useRef, useState} from "react";
import {Box} from '@material-ui/core';
import {useStyles} from '../cards/cardPayment.style';
import CircularProgress from "@material-ui/core/CircularProgress";
import {apiRequest} from "../../util/util";
import {API_GET_ORDER_TOKEN, API_STORE_ORDER} from "../../util/constants";
import {connect} from "react-redux";
import useNotify from "../../hooks/useNotify";
import './cardPaymentCardDetailsStep.scss';
import {cartPaymentAmountUpdate, cartPaymentStatusUpdate} from "../../store/cart/cartActions";
import {useHistory} from "react-router-dom";
import routes from "../../util/routes";

const getVendorFormConfig = (containerElementId, totalAmount, token, onLoadCallback) => {
  return {
    paymentDetails: {
      amount: Math.round((totalAmount * 100)).toString(),
      currencyCode: "826",
      paymentToken: token
    },
    containerId: containerElementId,
    fontCss: ['https://fonts.googleapis.com/css?family=Do+Hyeon'],
    styles: {
      base: {
        default: {
          color: "black",
          textDecoration: "none",
          fontFamily: "'Do Hyeon', sans-serif",
          boxSizing: "border-box",
          padding: ".375rem .75rem",
          boxShadow: 'none',
          fontSize: '1rem',
          borderRadius: '.25rem',
          lineHeight: '1.5',
          backgroundColor: '#fff',
        },
        focus: {
          color: '#495057',
          borderColor: '#80bdff',
        },
        error: {
          color: "#B00",
          borderColor: "#B00"
        },
        valid: {
          color: "green",
          borderColor: 'green'
        },
        label: {
          display: 'none'
        }
      },
      cv2: {
        container: {
          width: "50%",
          float: "left",
          boxSizing: "border-box"
        },
        default: {
          borderRadius: "0 .25rem .25rem 0"
        }
      },
      expiryDate: {
        container: {
          width: "50%",
          float: "left",
          borderRadius: '0rem',
        },
        default: {
          borderRadius: "0",
          borderRight: "none"
        },
      },
      cardNumber: {
        container: {
          width: "100%",
          float: "left"
        },
        default: {
          borderRadius: ".25rem 0 0 .25rem",
          borderRight: "none"
        },
      }
    },
    onIframeLoaded: onLoadCallback
  };
};

const transactionStatusMap = {
  0: 'Successful',
  4: 'Referred',
  5: 'Declined',
  20: 'Duplicate Transaction',
  30: 'Failed',
  400: 'Invalid Request',
  401: 'Issue with Access Token',
  404: 'No Access Token Supplied',
  500: 'Internal Server Error'
};
const renewTokenWhenStatus = [20, 30, 400, 401];

function CardPaymentCardDetailsStep({address, initiatePayment, stepBackHandler, paymentStatusHandler, ...otherProps}) {

  const classes = useStyles();
  const [notify] = useNotify();
  const history = useHistory();

  const [paymentHandler, setPaymentHandler] = useState();

  const cardPaymentFormRef = useRef();

  const [tokenState, setTokenState] = useState({
    loading: true,
    token: '',
    id: '',
    initialized: false
  });

  const [paymentState, setPaymentState] = useState({
    total: 0,
    processing: false,
    paid: false
  });

  // get token if payment type is card & don't have token
  useEffect(() => {
    // don't continue if token exists
    if (tokenState.token.length) return;

    applyToken();

  }, []);

  // initialize form after getting token
  useEffect(() =>{

    // dont try to initialize if token not available or has been used
    if (!tokenState.token.length || tokenState.initialized) return;

    initializeVendorForm();

  }, [tokenState.token]);

  useEffect(() => {
    if (!initiatePayment) return;

    makePayment();

  }, [initiatePayment]);

  const initializeVendorForm = () => {

    // prevent duplicate payment
    if (paymentState.paid) {
      notify.info('You\'ve already paid for this order');
      return false;
    }

    // initialize form
    const config = getVendorFormConfig('card-payment-form', paymentState.total, tokenState.token, () => {
      setTokenState({
        ...tokenState,
        loading: false,
        initialized: true
      });
    });

    const connectE = new window.Connect.ConnectE(config);

    setPaymentHandler(connectE);

    // makePayment();
  };

  const applyToken = async () => {

    try {

      const response = await getToken();

      setTokenState({
        ...tokenState,
        loading: false,
        initialized: false,
        ...response.data
      });

      return response.data;

    } catch (e) {
        notify.error('SERVER ERROR: Something went wrong. Try again later!');
        return false;
    }

  };

  const getToken = async () => {

    let totalAmount = otherProps.totalItemCost + otherProps.totalAddonsCost;
    totalAmount += otherProps.orderDelivery.charge;
    totalAmount -= otherProps.orderDiscount.value;
    totalAmount -= otherProps.orderCoupon.value;

    setPaymentState({
      ...paymentState,
      total: totalAmount
    });

    let tokenRequestData = {
      amount: totalAmount.toFixed(2),
    }

    const orderToken = await apiRequest.post(API_GET_ORDER_TOKEN, tokenRequestData);
    return orderToken
  };

  const updateToken = async (connectE) => {

    // only renew the token if existing token is used
    if (!tokenState.initialized) return;

    const newTokenResponse = await applyToken();

    const paymentDetails = {
      amount: Math.round(paymentState.total * 100),
      currencyCode: "826",
      paymentToken: newTokenResponse.token
    }

    // clear existing form
    cardPaymentFormRef.current.innerHTML = '';

    connectE.updateAccessToken(paymentDetails);
  };

  const makePayment = async () => {

    // validation
    try {

      await paymentHandler.validate();

    } catch (errs) {
      notify.error(errs[0]?.message);
    }

    // payment instruction
    try {

      paymentStatusHandler(true);

      const paymentResponse = await paymentHandler.executePayment(address);

      if (paymentResponse.statusCode === 0) {
        notify.success('Payment successful');

        otherProps.updatePaymentAmount(paymentState.total);
        otherProps.updatePaymentStatus(true);

        setPaymentState({
          ...paymentState,
          paid: true
        })

        // save order
        await saveOrder();

        // send to success page
        history.push(routes.orderSuccess);

      } else {

        if (paymentResponse.statusCode === 5 && paymentResponse.message.includes('AVS')) {
          notify.error(`Please check your billing address.`);

          // move to billing address step
          stepBackHandler();

        } else {
          notify.error(`Transaction status: ${transactionStatusMap[paymentResponse.statusCode]}. Please try again or use different payment method.`);
        }

      }

      paymentStatusHandler(false);

      // renewing token if transactions is unsuccessful for retry
      const issueFound = renewTokenWhenStatus.findIndex(issue => issue === paymentResponse.statusCode);

      if (issueFound !== -1) { // token issue
        await updateToken(paymentHandler);
      }


    } catch (e) {
        paymentStatusHandler(false);
        await handlePaymentFail(paymentHandler, e);
    }

  };

  const handlePaymentFail = async (connectE, err) => {

    // check if we need to renew token if relevant error occurred
    const issueFound = renewTokenWhenStatus.findIndex(issue => issue === err.statusCode);

    if (issueFound !== -1) { // token issue
      notify.error(`Transaction failed: ${transactionStatusMap[err.statusCode]}. Please try again or use different payment method.`);
      await updateToken(connectE);
    }

  };

  const saveOrder = async () => {

    let totalAmount = otherProps.totalItemCost + otherProps.totalAddonsCost;
    totalAmount += otherProps.orderDelivery.charge;
    totalAmount -= otherProps.orderDiscount.value;
    totalAmount -= otherProps.orderCoupon.value;
    totalAmount += otherProps.cart.adjustPointMoney;

    const {cart} = otherProps;

    const orderRequestData = {
      items: cart.items.map(itm => ({
        id: itm.id,
        qty: itm.qty
      })),
      addons: cart.addons.map(itm => ({
        id: itm.id,
        item_id: itm.item_id
      })),
      coupon: {
        ...cart.coupon,
        amount: cart.coupon.value,
      },
      discount: {
        ...cart.discount,
        amount: cart.discount.value.toFixed(2),
      },
      points: cart.orderPoint,
      delivery: {
        address_id: cart.delivery.address.id,
        charge: cart.delivery.charge,
        distance: cart.delivery.distance,
        date: cart.delivery.date,
        time: cart.delivery.time,
        is_asap: cart.delivery.isAsapTime
      },
      order: cart.order,
      payment: {
        ...cart.payment,
        amount: totalAmount.toFixed(2),
        trxId: tokenState.id,
        status: true,
      }
    };

    if (otherProps.orderType === "Collection") {
      orderRequestData.delivery = {
        address_id: "",
        date: cart.delivery.date,
        time: cart.delivery.time,
      };
    }

    try {

      const order = await apiRequest.post(API_STORE_ORDER, orderRequestData);

    } catch (e) {
      notify.error('Something went wrong, Please try again in a while.');
    }

  };

  return (<>
    {
      tokenState.loading &&
      <Box display="flex" justifyContent="center" m={2}>
        <CircularProgress />
      </Box>
    }

    {
      !tokenState.loading &&
      <Box>
        <div id="card-payment-form" ref={cardPaymentFormRef} />
      </Box>
    }
  </>);
}

const mapStateToProps = state => ({
  totalItemCost: state.cart.itemsTotal,
  totalAddonsCost: state.cart.addonsTotal,
  orderDiscount: state.cart.discount,
  orderCoupon: state.cart.coupon,
  orderDelivery: state.cart.delivery,
  cart: state.cart,
});

const mapDispatchToProps = dispatch => ({
  updatePaymentAmount: (amount) => dispatch(cartPaymentAmountUpdate(amount)),
  updatePaymentStatus: (status) => dispatch(cartPaymentStatusUpdate(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardPaymentCardDetailsStep);

import axios from 'axios';
import { API_BASE_URL, APP_TIMEZONE, PRE_AUTH_ENDPOINT } from './constants';
import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import store from '../store';
import _ from 'lodash';
import { updateIsRestaurantClosed, updateOpeningHoursOpenState } from '../store/opening/openingActions';
import useNotify from '../hooks/useNotify';
import { loginRequest, updateErrors, updateRequireAuth } from '../store/user/userActions';
import logOut from '../pages/auth/logOut';
import login from '../pages/auth/login';

export const getThemeStaticAsset = (assetPath) => `/static/theme/${assetPath}`;

export const apiRequest = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  withCredentials: true, 
});



const responseHandler = response => {
  return response;
};

const errorHandler = async (error)  =>  {
  if(error.response.status===419){
    store.dispatch(updateRequireAuth(true));
  }
  return Promise.reject(error);
};


apiRequest.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);




export const moneyFormat = (amount, fraction = 2) => {
  return parseFloat(amount.toFixed(fraction));
};

export const getDateFormat = (separator = '-') => `yyyy${separator}MM${separator}dd`;
export const getTimeFormat = (capitalAmPm = false) => `hh:mm ${capitalAmPm ? 'aa' : 'aaa'}`;

export const formatDateTime = (dateTime, format = '') => {
  const date = typeof dateTime === 'object' ? dateTime : new Date(dateTime);
  const dateFormat = format.length ? format : getDateFormat();

  return dateFns.format(date, dateFormat);
}

export const mergeDateTime = (dateObj, timeObj) => {
  let timestampWithDate = dateFns.setHours(dateObj, timeObj.getHours())
    .setMinutes(timeObj.getMinutes(), timeObj.getSeconds())
    ;
  return dateFns.toDate(timestampWithDate);
};

export function getTodaysTimeFromTimeString(timeString, getTimeStamp = false, timeSeparator = ':') {

  const timeObj = makeTimeFromTimeString(timeString, timeSeparator);

  if (getTimeStamp) {
    return timeObj.getTime();
  }

  const timeObjWithZone = applyTimeZone(dateFns.toDate(timeObj), APP_TIMEZONE);

  return timeObjWithZone;
}

/**
 * Converts time string (11:30:20) to Date object
 * @param timeString | String
 * @param timeSeparator | String
 * @returns {Date} | Date
 */
export function makeTimeFromTimeString(timeString, timeSeparator = ':') {
  if (typeof timeString !== 'string') {
    throw new Error(`Time string must be of type string, given: ${typeof timeSeparator}`);
  }

  const [hour, minute, second] = timeString.split(timeSeparator).map(itm => parseInt(itm));

  let timestamp = dateFns.setHours(new Date(), hour).setMinutes(minute, second || 0);

  return dateFns.toDate(timestamp);
}

export function applyTimeZone(dateObj, timezone = 'Europe/London') {

  if (!(dateObj instanceof Date)) {
    throw new Error(`'dateObj' must be instance of 'Date', type of '${typeof dateObj}' given`);
  }

  return dateFnsTz.utcToZonedTime(dateObj, timezone);
}

export function formatWithTimeZone(dateObj, format, timeZone = 'Europe/London') {

  if (!(dateObj instanceof Date)) {
    throw new Error(`'dateObj' must be instance of 'Date', type of '${typeof dateObj}' given`);
  }

  if (typeof format !== 'string') {
    throw new Error(`'format' must be a valid format string, type of '${typeof format}' given`);
  }

  const timeWithTimeZone = applyTimeZone(dateObj, timeZone);

  return dateFnsTz.format(timeWithTimeZone, format, { timeZone: timeZone });

}

export function isRestaurantClosed(hours) {
  
  const [toHour, toMinute] = hours.to.split(':');

  const hoursDetails = dateFns.setHours(dateFns.setMinutes(applyTimeZone(new Date()), toMinute), toHour);
  
  const currentDate = applyTimeZone(new Date());

  if(currentDate.getTime()>hoursDetails.getTime()){
    store.dispatch(updateIsRestaurantClosed(true));
  }else{
    store.dispatch(updateIsRestaurantClosed(false));
  }
}

export function getTimeFormatFromTimeString(timeString, format = '', applyTimezone = true) {

  let timeObj = makeTimeFromTimeString(timeString);

  if (applyTimezone) {
    timeObj = applyTimeZone(timeObj, APP_TIMEZONE);
  }

  const finalFormat = format.length ? format : getTimeFormat();

  return dateFns.format(timeObj, finalFormat);
}

export const getValidOpeningStatus = (hours) => {

  // don't try to calculate if hours unavailable
  if (_.isEmpty(hours)) return false;

  const currentTimestamp = applyTimeZone(new Date(), APP_TIMEZONE).getTime();

  // convert timings to timestamps
  const validTimings = hours.filter(hour => {
    const fromTimestamp = getTodaysTimeFromTimeString(hour.from, true);
    const toTimestamp = getTodaysTimeFromTimeString(hour.to, true);
    return ((currentTimestamp >= fromTimestamp) && (currentTimestamp <= toTimestamp));
  });

  const openingState = !!validTimings.length;


  // update store
  store.dispatch(updateOpeningHoursOpenState(openingState));

  return openingState;
}
